import React, { useMemo } from 'react';
import { graphql, useStaticQuery, PageProps } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { SafesFilters, SafesResults } from '../components/sections';
import { IResponseBestsellers } from '.';
import { CannonBreadcrumbs } from '../atoms';
import { cannonStyled } from '../theme';
import { useQueryObjState } from '../hooks/useQueryState';
import { useTableOrMobile } from '../hooks';

const query = graphql`
  query {
    allStrapiCannonSafes {
      edges {
        node {
          id
          displayName
          slug
          reviews {
            id
            title
            value
            description
          }
          safeCategory {
            title
          }
          series {
            title
          }
          lifeStyleVariations {
            title
          }
          averagePrice
          firearmCapacity
          specifications
          displayImage {
            alternativeText
            ext
            hash
            url
            formats {
              thumbnail {
                ext
                hash
                url
              }
            }
          }
        }
      }
    }
    allStrapiSafeCategory {
      edges {
        node {
          title
        }
      }
    }
    allStrapiLifeStyleVariation {
      edges {
        node {
          title
        }
      }
    }
  }
`;

export interface FilterOption {
  title: string;
}
interface IData extends IResponseBestsellers {
  allStrapiSafeCategory: {
    edges: Array<{ node: FilterOption }>;
  };
  allStrapiLifeStyleVariation: {
    edges: Array<{ node: FilterOption }>;
  };
}

export interface ISafesFilters {
  bottomPrice: number;
  topPrice: number;
  type: Array<string>;
  fireArmsCapacity: Array<string>;
  features: Array<string>;
  bestFor: Array<string>;
  maxHeight: number;
  maxWidth: number;
  maxDepth: number;
}

export const defaultSafeFilters: ISafesFilters = {
  bottomPrice: 100,
  topPrice: 5000,
  type: [],
  fireArmsCapacity: [],
  features: [],
  bestFor: [],
  maxHeight: 100,
  maxWidth: 100,
  maxDepth: 100,
};

interface IisMobile {
  isMobile: boolean;
}

const SafesWrapper = cannonStyled(Wrapper, (props: IisMobile) => ({
  display: 'flex',
  maxWidth: '1400px',
  padding: props.isMobile ? '50px 10px' : '50px',
  marginLeft: 'auto',
  marginRight: 'auto',
  overflow: 'hidden',
}));

const BreadCrumbsContainer = cannonStyled('div', (props: IisMobile) => ({
  position: 'absolute',
  top: props.isMobile ? '10px' : '30px',
  left: props.isMobile ? '16px' : '40px',
  width: '250px',
}));

const getMapedEdges = (responseArray) => {
  return responseArray.edges.map((el) => el.node);
};

const SafesPage: React.FC<PageProps> = (props: PageProps) => {
  const data = useStaticQuery<IData>(query);
  const [filters, setFilters, resetValues] = useQueryObjState(
    props.location.search,
    defaultSafeFilters
  );
  const [isMobile, isTablet] = useTableOrMobile();

  const [filteredSafes, recommendations] = useMemo(() => {
    const allSafes = data.allStrapiCannonSafes.edges.map((el) => el.node);
    const filteredSafes = allSafes.filter((s) => {
      // price filters
      // if (
      //   s.averagePrice < filters.bottomPrice ||
      //   s.averagePrice > filters.topPrice
      // ) {
      //   return false;
      // }
      // dimensions filters
      const features = s.specifications.find(
        (spec) => spec.title === 'Features'
      );
      if (
        features === undefined ||
        Number(
          features.hightlight
            .find((h) => h.name === 'Exterior Height')
            ?.value.split(' ')[0]
        ) > filters.maxHeight ||
        Number(
          features.hightlight
            .find((h) => h.name === 'Exterior Width')
            ?.value.split(' ')[0]
        ) > filters.maxWidth ||
        Number(
          features.hightlight
            .find((h) => h.name === 'Exterior Depth')
            ?.value.split(' ')[0]
        ) > filters.maxDepth
      ) {
        return false;
      }
      // Waterproof filters
      if (
        filters.features.includes('waterproof') &&
        features.list.find((feature) => feature.name === 'Waterproof')
          ?.value !== 'Yes'
      ) {
        return false;
      }
      // Biometric locks filters
      if (
        filters.features.includes('biometricLocks') &&
        features.list.find((feature) => feature.name === 'Lock Type')?.value !==
          'Biometric'
      ) {
        return false;
      }
      //Firearm capacity filters
      if (filters.fireArmsCapacity.length > 0) {
        const fireArmCapacity = s.firearmCapacity;
        if (fireArmCapacity === null || fireArmCapacity === 0) return false;
        const validCapacities = filters.fireArmsCapacity.map((fc) =>
          fc.split('-')
        );
        let validCapacity = false;

        validCapacities.forEach((element) => {
          if (
            fireArmCapacity > Number(element[0]) &&
            fireArmCapacity < Number(element[1])
          ) {
            validCapacity = true;
          }
        });
        if (!validCapacity) return false;
      }
      // Adjustable Shelves filters
      if (
        filters.features.includes('adjustableShelves') &&
        isNaN(
          Number(
            s.specifications
              .find((spec) => spec.title === 'Capacity')
              ?.list.find((c) => c.name === 'Adjustable Shelves Count')?.value
          )
        )
      ) {
        return false;
      }
      //type filters
      if (
        filters.type.length > 0 &&
        !filters.type.includes(s.safeCategory.title)
      ) {
        return false;
      }
      // bestFor filters
      if (
        filters.bestFor.length > 0 &&
        !s.lifeStyleVariations.find((e) => filters.bestFor.includes(e.title))
      ) {
        return false;
      }
      return true;
    });
    return [filteredSafes, allSafes.slice(0, isTablet ? 2 : 3)];
  }, [filters, isTablet]);

  const [typeOptions, bestForOptions] = useMemo(
    () => [
      getMapedEdges(data.allStrapiSafeCategory),
      getMapedEdges(data.allStrapiLifeStyleVariation),
    ],
    []
  );

  const breadCrumbsPages = useMemo(() => {
    const bPages = [
      { title: 'Home', href: '/' },
      { title: 'Safes', href: '/safes' },
    ];
    if (filters?.type?.length === 1 && filters?.bestFor?.length === 0) {
      bPages.push({ title: filters.type[0], href: '' });
    }
    if (filters?.bestFor?.length === 1 && filters?.type?.length === 0) {
      bPages.push({ title: filters.bestFor[0], href: '' });
    }
    return bPages;
  }, [filters.type.length]);

  let addTitle = props.location.search
    ? props.location.search.split('=')[1].split('+').join(' ')
    : '';

  return (
    <LayoutProvider
      seo
      locationSearch={props.location.search}
      titleExt={addTitle}
    >
      <SafesWrapper isMobile={isMobile} noScroll>
        <BreadCrumbsContainer isMobile={isMobile}>
          <CannonBreadcrumbs pages={breadCrumbsPages} />
        </BreadCrumbsContainer>
        <SafesFilters
          {...filters}
          typeOptions={typeOptions}
          bestForOptions={bestForOptions}
          setFilters={setFilters}
          resetFilters={resetValues}
        />
        <SafesResults safes={filteredSafes} recommendations={recommendations} />
      </SafesWrapper>
    </LayoutProvider>
  );
};

export default SafesPage;